<template>
  <div class="mt-48">
    <div class="vs-row w-full h-full bg-cover backgroundImage">
      <div class="transbox text-white py-8 px-16">
        <div class="vs-row">
          <div class="vs-col xl:w-1/4 md:w-1/3 sm:w-1/2 w-full">
            <div class="text-left">
              <img style="height: 40px; width: 60px;" src="@/assets/images/car.svg" alt="">
              <p class="headers">Assured Purchase</p>
              <p class="w-11/12">Provide genuine service & store products</p>
              <div class="flex">
                <span class="material-icons mr-1">check_circle</span>
                <span>100% genuine products</span> 
              </div>
              <div class="flex">
                <span class="material-icons mr-1">check_circle</span>
                <span>Deliver items at door</span> 
              </div>
              <div class="flex">
                <span class="material-icons mr-1">check_circle</span>
                <span>Free return products</span> 
              </div>
            </div>
          </div>
          <div class="vs-col xl:w-1/4 md:w-1/3 sm:w-1/2 sm:mt-0 mt-10 w-full">
            <div class="text-left">
              <img style="height: 40px; width: 50px;" src="@/assets/images/money.svg" alt="">
              <p class="headers">Easy To Buy & Return</p>
              <p>Single click to buy & return products</p><br>
              <div class="flex">
                <span class="material-icons mr-1">check_circle</span>
                <span>Same day delivery available</span> 
              </div>
              <div class="flex">
                <span class="material-icons mr-1">check_circle</span>
                <span>Click to return products</span> 
              </div>
              <div class="flex">
                <span class="material-icons mr-1">check_circle</span>
                <span>Quick support links available</span> 
              </div>
            </div>
          </div>
          <div class="vs-col xl:w-1/4 md:w-1/3 md:mt-0 mt-10 sm:w-1/2 w-full">
            <div class="text-left">
              <img style="height: 40px; width: 45px;" src="@/assets/images/visa.svg" alt="">
              <p class="headers">Secure Payments</p>
              <p>Accepts all credit & debit cards</p><br>
              <div class="flex">
                <span class="material-icons mr-1">check_circle</span>
                <span>100% payment security</span> 
              </div>
              <div class="flex">
                <span class="material-icons mr-1">check_circle</span>
                <span>Confirmation authentity</span> 
              </div>
              <div class="flex">
                <span class="material-icons mr-1">check_circle</span>
                <span>Delivery items at door</span> 
              </div>
            </div>
          </div>
          <div class="vs-col xl:w-1/4 lg:w-1/3 md:w-full xl:mt-0 lg:mt-10 mt-10 sm:w-1/2 w-full">
             <div class="text-left">
              <img style="height: 40px; width: 35px;" src="@/assets/images/download.svg" alt="">
              <p class="headers">Download Center</p>
              <p>Use mobile app and get biggest discounts</p>
              <div class="flex" style="padding-left: -45px">
                <div class="w-96"><img src="@/assets/images/googleplay.svg" alt=""></div>
                <div class="w-96"><img src="@/assets/images/appstore.svg" alt=""></div> 
              </div>
            </div>
          </div>
        </div>
        
        <div class="vs-row pt-14">
          <div class="vs-col xl:w-1/4 md:w-1/3 sm:w-1/2 w-full">
            <div class="text-left">
              <img class="w-1/3" src="@/assets/images/logo.svg" alt="">
              <p class="mt-5 w-4/5">Join's experience spans a wide array of project types. Among all that is new, Concrete would find one thing unchanged Concrete's commitment to building</p>
            </div>
          </div>
          <div class="vs-col xl:w-1/4 md:w-1/3 sm:w-1/2 sm:mt-0 mt-10 w-full">
            <p class="headers">Links</p>
            <div class="mt-2"><span class="cursor-pointer" @click="goToPage('home')">Home</span></div>
            <div class="mt-2"><span class="cursor-pointer" @click="goToPage('store')">Store</span></div>
            <div class="mt-2"><span class="cursor-pointer"></span></div>
            <div class="mt-2"><span class="cursor-pointer" @click="goToPage('cart')">Cart</span></div>
            <div class="mt-2"><span class="cursor-pointer" @click="goToPage('favourites')">Wish List</span></div>
          </div>
          <div class="vs-col xl:w-1/4 md:w-1/3 md:mt-0 mt-10 sm:w-3/5 w-full">
            <div class="text-left">
              <p class="headers">Contact Us</p>
              <p class="mt-2 lg:w:11/12 sm:w-3/4">building 167 street 28 - District No 5 - The 5th District - New Kuait City, kuait Governorate</p><br>
              <p>Email: info@royal_pharm.com</p>
              <p>Tel: 2930-3303-22</p>
              <p>Fax: (+202) 2268 1308</p>
              <div class="flex headers font-bold text-xl">
                <span class="material-icons mr-1">local_phone</span>
                <span>1881010</span> 
              </div>
            </div>
          </div>
          <div class="vs-col xl:w-1/4 md:w-full lg:mt-0 mt-10 sm:w-2/5 w-full">
            <p class="headers">Follow Us</p>
            <div class="flex mt-2">
              <img @click="openLink('https://www.facebook.com/justt.joinn/')" class="cursor-pointer mr-2 w-6" src="@/assets/images/facebook.svg" alt="">
              <img @click="openLink('')" class="cursor-pointer mr-2 w-6" src="@/assets/images/gmail.svg" alt="">
              <img @click="openLink('https://twitter.com/join_app')" class="cursor-pointer mr-2 w-6" src="@/assets/images/twiter.svg" alt="">
              <img @click="openLink('https://www.instagram.com/join.kw/')" class="cursor-pointer mr-2 w-6" src="@/assets/images/instagram.svg" alt="">
              <img @click="openLink('')" class="cursor-pointer mr-2 w-6" src="@/assets/images/linkedin.svg" alt="">
              <img @click="openLink('')" class="cursor-pointer w-6" src="@/assets/images/youtube.svg" alt="">
            </div>
            <div class="mt-10">
              <p class="headers">Accepted Payment Methods</p>
              <img class="mt-2 w-80" src="@/assets/images/group.svg" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vs-row w-full sm:h-16 h-20" style="background-color: #F89416;">
      <div class="vs-row w-11/12 m-auto h-full">
        <div class="vs-col w-1/2 sm:text-left text-center sm:mt-5 mt-2">
          <p class="text-white pl-5">Copyright &copy;Join KUIT {{new Date().getFullYear()}}</p>
        </div>
        <div class="vs-col w-1/2 sm:text-right text-center sm:mt-5 mt-2">
          <div class="vs-col xl:w-2/3 lg:w-3/5 md:w-2/5 sm:w-2/5 w-full">
            <p class="text-white pr-5">Privacy Policy</p>
          </div>
          <div class="vs-col xl:w-1/3 lg:w-2/5 md:w-3/5 sm:w-3/5 w-full">
            <p class="text-white pr-5">Terms And Conditions</p>
          </div>
        </div> 
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  methods: {
    openLink (link) {
      window.open(link, 'blank')
    },
    goToPage (name) {
      this.$router.push({
        name,
        params: {
          categoryId: name === 'store' ? '0' : ''
        }
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>
.backgroundImage {
  background-image: url("../assets/images/footer.jpg");
  height: 550px;
}
.transbox {
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.85;
}
.headers {
  color: #F89416;
  margin-top: 5px;
  font-weight: 500;
}
.material-icons {
  color: #F89416;
}
@media (max-width: 1279px) {
  .backgroundImage {
    height: 980px !important;
  }
}
@media (max-width: 1023px) {
  .backgroundImage {
    height: 1080px !important;
  }
}
@media (max-width: 913px) {
  .backgroundImage {
    height: 1130px !important;
  }
}
@media (max-width: 800px) {
  .backgroundImage {
    height: 1170px !important;
  }
}
@media (max-width: 767px) {
  .backgroundImage {
    height: 1130px !important;
  }
}
@media (max-width: 639px) {
  .backgroundImage {
    height: 2030px !important;
  }
}
@media (max-width: 390px) {
  .backgroundImage {
    height: 2090px !important;
  }
}
@media (max-width: 340px) {
  .backgroundImage {
    height: 2250px !important;
  }
}
</style>