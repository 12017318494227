<template>
  <div class="centerx">
    <vs-navbar
      v-model="indexActive"
      text-color="black"
      active-text-color="#00DCDC"
      class="nabarx m-auto mt-2 pb-3">
      <div class="flex xl:ml-20 md:ml-0 mobileLogo" slot="title">
        <vs-navbar-title class="mt-6 xl:ml-12 lg:ml-4 md:ml-0 mobile">
          <div class="flex">
            <vs-input @keyup.native.enter="searchString()" class="text-left search-input" color="dark" icon-after="true" placeholder="Let's Explore..." v-model="search"/>
            <i @click="openFilterModal()" class="vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon material-icons null icon-after">filter_alt</i>
            <!-- <vs-icon @click="openFilterModal()" icon="filter_alt" class="absolute mt-1 ml-1" size="small" color="primary"></vs-icon> -->
          </div>
        </vs-navbar-title>
        <vs-navbar-title>
          <img class="w-20" src="@/assets/images/logo.png" alt="">
        </vs-navbar-title>
        <vs-navbar-title class="mt-6 xl:ml-12 lg:ml-4 md:ml-0 pc">
          <div class="flex">
            <vs-input @keyup.native.enter="searchString()" class="text-left search-input" color="dark" icon-after="true" placeholder="Let's Explore..." v-model="search"/>
            <i @click="openFilterModal()" class="vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon material-icons null icon-after">filter_alt</i>
            <!-- <vs-icon @click="openFilterModal()" icon="filter_alt" class="absolute cursor-pointer mt-1 ml-1" size="small" color="primary"></vs-icon> -->
          </div>
          <vs-popup classContent="searchPopup" title="FILTERS" :active.sync="popupActive">
            <vs-collapse accordion type="margin" class="col-item">
              <div class="vs-row">
                <vs-collapse-item icon-arrow="add" class="vs-col xl:w-1/3 md:w-1/3 w-full">
                  <div slot="header">
                    Categories
                  </div>
                  <ul class="leftx">
                    <li class=" justify-start mt-1" v-for="option in interests" :key="option.value">
                      <h5>{{option.name}}</h5>
                      <vs-checkbox color="#00DDCC" class="block mb-1" v-for="subInterest in option.sub_interests" :key="subInterest.value" :vs-value="subInterest.sub_interests_id" v-model="filter.categories">{{subInterest.sub_interests_name}}</vs-checkbox>
                    </li>
                  </ul>
                </vs-collapse-item>
                <vs-collapse-item icon-arrow="add" class="vs-col xl:w-1/3 md:w-1/3 w-full">
                  <div slot="header">
                    Area
                  </div>
                  <ul class="leftx">
                    <li class="justify-start mt-1" v-for="option in areas" :key="option.value">
                      <h5>{{option.gov}}</h5>
                      <vs-checkbox class="mb-1" color="#00DDCC" v-for="area in option.areas" :key="area.index" :vs-value="area.id" v-model="filter.areas">{{area.name}}</vs-checkbox>
                    </li>
                  </ul>
                </vs-collapse-item>
                <vs-collapse-item icon-arrow="add" class="vs-col xl:w-1/3 md:w-1/3 w-full">
                  <div slot="header">
                    Members
                  </div>
                  <ul class="leftx">
                    <li class="flex border-b-2 py-1 border-gray-100 text-gray-500" v-for="option in members" :key="option.value">
                      <div class="vs-col w-3/4 flex justify-start">
                        <vs-checkbox :vs-value="option.child_id" :class="{'text-blue':check}" color="#00DDCC" v-model="filter.members">{{option.child_name}}</vs-checkbox>
                      </div>
                      <div class="vs-col w-1/4">
                        <vs-avatar :class="{'user-profile-img':check}" :src="option.child_photo" size="30px" />
                      </div>
                    </li>
                  </ul>
                </vs-collapse-item>
              </div>
              <div class="vs-row">
                <vs-collapse-item icon-arrow="add" class="vs-col xl:w-1/3 md:w-1/3 w-full">
                  <div slot="header">
                    Average Rating
                  </div>
                  <div class="flex justify-center">
                    <star-rating class="pt-1"
                    :border-width="3" 
                    :star-size="17" 
                    :rating="filter.rate" 
                    :increment="0.5"
                    :rtl="false" 
                    :show-rating="false" 
                    inactive-color="#FFF" 
                    active-color="#00DDCC" 
                    border-color="#00DDCC" 
                    :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]" />
                  </div>
                </vs-collapse-item>
                <vs-collapse-item icon-arrow="add" class="vs-col xl:w-1/3 md:w-1/3 w-full">
                  <div slot="header">
                    Price
                  </div>
                  <div class="text-center pt-7 px-5 text-gray-500">
                    <vs-slider :min="0" :max="3000" color="#00DDCC" v-model="filter.price_from"/>
                    <p>Min Price - KD {{filter.price_from}}</p>
                  </div>
                  <div class="text-center pt-6 px-5 text-gray-500">
                    <vs-slider :min="0" :max="3000" color="#00DDCC" v-model="filter.price_to"/>
                    <p>Max Price - KD {{filter.price_to}}</p>
                  </div>
                </vs-collapse-item>
                <vs-collapse-item icon-arrow="add" class="vs-col xl:w-1/3 md:w-1/3 w-full">
                  <div slot="header">
                    Age Range
                  </div>
                  <div class="text-center pt-7 px-5 text-gray-500">
                    <vs-slider :min="0" :max="60" color="#00DDCC" v-model="filter.age_from"/>
                    <p>Min Age - {{filter.age_from}}</p>
                  </div>
                  <div class="text-center pt-6 px-5 text-gray-500">
                    <vs-slider :min="0" :max="60" color="#00DDCC" v-model="filter.age_to"/>
                    <p>Max Age - {{filter.age_to}}</p>
                  </div>
                </vs-collapse-item>
              </div>
            </vs-collapse>
            <div class="vs-row text-center">
              <vs-button class="btn w-1/3 mt-7" color="warning" @click="filterPage()" type="filled">Apply</vs-button>
            </div>
          </vs-popup>
        </vs-navbar-title>
      </div>
      <vs-navbar-item index="0" class="navItems">
        <a class="cursor-pointer" @click="page('home')">Home</a>
      </vs-navbar-item>
      <vs-navbar-item index="1" class="navItems">
        <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
          <a href="#">Store</a>
          <vs-dropdown-menu class="navigation" style="left: 68% !important;">
            <div class="divide-y divide-gray-300 mx-3">
              <div class="pl-3 py-2 uppercase" style="color: #00DCDC;">Categories</div>
              <div class="vs-row w-full h-44">
                <div class="vs-col mt-2 lg:w-1/3 md:w-1/2 sm:w-full" v-for="item in categories" :key="item.index">
                  <vs-dropdown-item @click="openStore(item)">
                    {{$route.params.lang === 'en' ? item.name_en : item.name_ar}}
                  </vs-dropdown-item>
                </div>
              </div>
            </div>
          </vs-dropdown-menu>
        </vs-dropdown>
      </vs-navbar-item>
      <vs-navbar-item index="2" class="navItems">
        <a href="#">Activites</a>
      </vs-navbar-item>
      <vs-navbar-item index="3" class="navItems">
        <a href="#">About Us</a>
      </vs-navbar-item>
      <span index="4" class="navItems">
        <p class="cursor-pointer ml-3 mb-1" v-if="$route.params.lang === 'en'" @click="changeLang('ar')">العربية</p>
        <p class="cursor-pointer" v-else-if="$route.params.lang === 'ar'" @click="changeLang('en')">English</p>
      </span>

      <vs-spacer></vs-spacer>
      <div v-if="!loginFlag" class="xl:mr-20 md:mr-0 buttons">
        <vs-button @click="login('login')" color="#00DCDC" type="filled">Sign In</vs-button>
        <vs-button @click="login('register')" class="xl:ml-20 md:ml-2" color="#F89416" type="filled">Sign Up</vs-button>
      </div>
      <div v-else class="xl:mr-20 md:mr-2 buttons">
        <vs-avatar size="small" @click="cartPage()" text-color="dark"  badge-color="#F89416" :badge="cartNumber" icon-pack="material-icons" icon="shopping_cart"/>
        <vs-avatar size="small" @click="page('favourites')" text-color="dark" icon-pack="material-icons" icon="favorite"/>
        <vs-avatar size="small" @click="page('notifications')" text-color="dark"  icon-pack="material-icons" icon="notifications" badge="7" badge-color="#F89416"/>

        <!-- PROFILE -->

        <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
          <div class="con-img ml-3">
            <avatar :username="`${userData.fname} ${userData.lname}`"></avatar>
            <!-- <img key="onlineImg" src="https://avatars2.githubusercontent.com/u/31676496?s=460&v=4" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" /> -->
          </div>
          <vs-dropdown-menu class="p-0 vx-navbar-dropdown">
            <div class="mb-16 profile-header">
              <div class="relative">
                <div class="cover-container-nav rounded-t-lg">
                  <img :src="image" alt="user-profile-cover" class="h-32 w-full responsive block">
                </div>
                <div class="profile-image-container pointer-events-none">
                  <div>
                    <avatar class="user-profile pp-image" :username="`${userData.fname} ${userData.lname}`"></avatar>
                    <!-- <vs-avatar class="user-profile-image" src="https://avatars2.githubusercontent.com/u/31676496?s=460&v=4" size="85px" /> -->
                  </div>
                  <div class="mt-14">
                    <h2 class="text-xl text-blue">{{userData.fname}} {{userData.lname}}</h2>
                    <h2>{{userData.email}}</h2>
                  </div>
                </div>
              </div>
            </div>
              
            <ul class="p-0" style="min-width: 20rem">
              <li @click="profilePages('profile')" class="flex py-2 px-4 cursor-pointer hover:bg-primary">
                <vs-icon icon="perm_identity" size="small" color="primary"></vs-icon>
                <span class="ml-2">My Account</span>
              </li>
              <li class="flex py-2 px-4 cursor-pointer hover:bg-primary">
                <vs-icon icon="update" size="small" color="primary"></vs-icon>
                <span class="ml-2">Order History</span>
              </li>
              <li @click="profilePages('cart')" class="flex py-2 px-4 cursor-pointer hover:bg-primary">
                <vs-icon icon="shopping_cart" size="small" color="primary"></vs-icon>
                <span class="ml-2">My Cart</span>
              </li>
              <li @click="profilePages('notifications')" class="flex py-2 px-4 cursor-pointer hover:bg-primary">
                <vs-icon icon="notifications" size="small" color="primary"></vs-icon>
                <span class="ml-2">Notifications</span>
              </li>
              <li @click="profilePages('favourites')" class="flex py-2 px-4 cursor-pointer hover:bg-primary">
                <vs-icon icon="favorite" size="small" color="primary"></vs-icon>
                <span class="ml-2">Favorite</span>
              </li>
              <li @click="openlink('https://api.WhatsApp.com/send?phone=96592294872')" class="flex py-2 px-4 cursor-pointer hover:bg-primary">
                <vs-icon icon="support_agent" size="small" color="primary"></vs-icon>
                <span class="ml-2">Contact Us</span>
              </li>
              <li class="flex py-2 px-4 cursor-pointer hover:bg-primary">
                <vs-icon icon="help_outline" size="small" color="primary"></vs-icon>
                <span class="ml-2">About Join</span>
              </li>
              <li @click="openLink('https://reachnetwork.co/Join_App')" class="flex py-2 px-4 cursor-pointer hover:bg-primary">
                <vs-icon icon="support_agent" size="small" color="primary"></vs-icon>
                <span class="ml-2">Follow Up</span>
              </li>
              <li class="flex py-2 px-4 cursor-pointer hover:bg-primary">
                <vs-icon icon="info" size="small" color="primary"></vs-icon>
                <span class="ml-2">Terms & conditiions</span>
              </li>
              <li @click="logout" class="flex py-2 px-4 cursor-pointer hover:bg-primary">
                <vs-icon icon="logout" size="small" color="primary"></vs-icon>
                <span class="ml-2">Logout</span>
              </li>
            </ul>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
    </vs-navbar>
  </div>
</template>

<script>
import image from '../assets/images/footer.jpg'
import StarRating from 'vue-star-rating'
import axios from '../axios.js'
import Avatar from 'vue-avatar'

export default {
  components: { StarRating, Avatar },
  data: ()=>({
    image,
    localStorage,
    cartNumber: 0,
    headers: {
      'auth-token': `${localStorage.getItem('access_token')}`
    },
    value: '',
    areas: [],
    members: [],
    indexActive: 0,
    interests: [],
    categories: [],
    search: '',
    userData: JSON.parse(localStorage.getItem('user_data')),
    loginFlag: Boolean(localStorage.getItem('access_token')),
    popupActive: false,
    check: true,
    filter: {
      members: [],
      areas: [],
      categories: [],
      rate: 0,
      age_from: 0,
      age_to: 60,
      price_from: 0,
      price_to: 3000
    },
    options:[
      {text: 'Square', value: 1},
      {text: 'Rectangle', value: 2},
      {text: 'Rombo', value: 3},
      {text: 'Romboid', value: 4},
      {text: 'Trapeze', value: 5},
      {text: 'Triangle', value: 6},
      {text: 'Polygon', value: 7}
    ]
  }),
  methods: {
    cartPage () {
      this.$router.push({name: 'cart'})
    },
    openLink (link) {
      window.open(link, 'blank')
    },
    login (name) {
      this.$router.push({name})
    },
    filterPage () {
      this.popupActive = false
      this.$router.push({
        name: 'search-results',
        query: {
          categories: this.filter.categories,
          areas: this.filter.areas,
          members: this.filter.members,
          rate: this.filter.rate,
          age_from: this.filter.age_from,
          age_to: this.filter.age_to,
          price_from: this.filter.price_from,
          price_to: this.filter.price_to,
        }
      })
    },
    changeLang (lang) {
      this.$router.push({
        params: {lang: lang}
      }).catch(() => {})
      if (lang !== 'en') {
        this.$vs.rtl = true
      } else {
        this.$vs.rtl = false
      }
    },
    profilePages (name) {
      this.$router.push({name})
    },
    logout () {
      localStorage.removeItem('access_token')
      localStorage.removeItem('user_data')
      localStorage.setItem('loggedIn', false)
      window.location.reload()
    },
    page (name) {
      this.$router.push({
        name
      })
    },
    loadCategories () {
      axios.get('v2/categories', {headers: this.headers})
      .then((response) => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.categories = response.data.payload.data
        }
      })
    },
    loadInterests () {
      axios.get('v1/interestsList', {headers: this.headers})
      .then((response) => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.interests = response.data.payload
        }
      })
    },
    openFilterModal () {
      this.filter = {
        members: [],
        areas: [],
        categories: [],
        rate: 0,
        age_from: 0,
        age_to: 60,
        price_from: 0,
        price_to: 3000
      }
      this.popupActive = true
      this.loadAreas()
      this.loadMembers()
    },
    loadAreas () {
      axios.get('v1/areaList', {headers: this.headers})
      .then((response) => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.areas = response.data.payload
        }
      })
    },
    loadMembers () {
      axios.post('v1/user/userChildDetails', {}, {headers: this.headers})
      .then((response) => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.members = response.data.payload.child_data
        }
      })
    },
    openStore (item) {
      this.$router.push({
        name: 'store',
        params: {
          categoryId: item.id
        }
      })
    },
    searchString () {
      console.log(11111)
      this.$router.push({
        name: 'search-results',
        query: {
          search: this.search 
        }
      })
    }
  },
  created () {
    this.loadInterests()
    this.loadCategories()
    setInterval(() => {
      this.cartNumber = JSON.parse(localStorage.getItem('cart')).length
    }, 100)
  }
}
</script>
<style scoped>
.vuesax-app-is-ltr .vs-input--icon.icon-after {
  border-right: 1px solid lightgrey;
  border-left: none;
  left: auto;
  right: unset;
  top: 28px !important;
  color: #00DCDC;
}
/* [dir = 'rtl'] .vuesax-app-is-ltr .vs-input--icon.icon-after {
  left: unset;
  right: auto;
} */
.pp-image {
  width: 65px !important;
  height: 65px !important;
}
.navigation {
  left: 783.859px !important;
}
.user-profile {
  border: 2px solid #00DCDC; 
}
.selectExample {
  width: 100%;
  padding: 0px 10px;
}
.btn {
  padding: 7px 0px;
  border-radius: 2px;
}
.profile-image-container {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -3.6rem;
  left: 5%;
  width: 60%;
  justify-content: space-between;
}
.user-profile-image {
  border: 2px solid white; 
}
.vs-dropdown--custom {
  padding: 0px !important;
}
</style>
<style>
.col-item .vs-collapse-item--icon-header {
  color: #00DCDC;
  font-size: 20px !important;
}
.vs-dropdown--custom {
  padding: 0px !important;
}
.centerx {
  border-top: 5px solid #00DCDC;
}
.vs-con-input {
  width: 450px;
}
.navItems {
  margin-right: 30px;
}
.con-vs-avatar {
  background-color: #fff !important;
}
@media (max-width: 800px) {
  .vs-con-input {
    width: 400px !important;
    margin-left: -370px;
  }
  .buttons {
    margin-left: 10px;
    padding-bottom: 7px;
    padding-top: 7px;
  }
}
@media (max-width: 750px) {
  .vs-con-input {
    width: 400px !important;
    margin-left: -320px;
  }
}
@media (max-width: 700px) {
  .vs-con-input {
    width: 400px !important;
    margin-left: -270px;
  }
}
@media (max-width: 650px) {
  .vs-con-input {
    width: 350px !important;
    margin-left: -220px;
  }
}
@media (max-width: 600px) {
  .vs-con-input {
    width: 350px !important;
    margin-left: -170px;
  }
}
@media (max-width: 550px) {
  .vs-con-input {
    width: 300px !important;
    margin-left: -120px;
  }
}
@media (max-width: 500px) {
  .vs-con-input {
    width: 250px !important;
    margin-left: -70px;
  }
}
@media (max-width: 450px) {
  .vs-con-input {
    width: 200px !important;
    margin-left: -30px;
  }
}
@media (max-width: 400px) {
  .vs-con-input {
    width: 200px !important;
    margin-left: 0px;
  }
}
@media (min-width: 1551px) {
  .vs-con-input {
    width: 550px !important;
  }
}
@media (max-width: 1550px) {
  .vs-con-input {
    width: 300px;
  }
  .buttons {
    margin-left: 10px;
    padding-bottom: 7px;
    padding-top: 7px;
  }
  .navItems {
    margin-right: 10px;
  }
}
@media (max-width: 1230px) {
  .buttons {
    margin-left: 7px;
    padding-bottom: 7px;
    padding-top: 7px;
  }
  .navItems {
    margin-right: 5px;
  }
}
@media (max-width: 1100px) {
  .vs-con-input {
    width: 230px;
  }
  .buttons {
    margin-left: 7px;
    padding-bottom: 7px;
    padding-top: 7px;
  }
}
@media (max-width: 920px) {
  .navItems {
    margin-right: -5px;
  }
}
@media (max-width: 850px) {
  .vs-con-input {
    width: 220px;
  }
  .buttons {
    margin-left: 3px;
  }
  .vs-navbar .vs-button {
    padding: 10px 10px 10px 10px !important;
  }
}
@media (max-width: 375px) {
  .mobile {
    margin-top: 15px;
  }
}
.vs-inputx {
  height: 40px !important;
  margin-top: -3px;
}
.vs-navbar .vs-button {
  margin-right: 10px !important;
  padding: 10px 20px 10px 20px;
}
@media screen and (max-width: 800px) {
  .mobile {
    visibility: visible;
  }
  .pc {
    display: none;
  }
  .mobileLogo {
    margin-right: 15px;
  }
  .vuesax-app-is-ltr .vs-input--icon.icon-after {
    border-right: 1px solid lightgrey !important;
    border-left: none !important;
    left: 70px !important;
    top: 28px !important;
    color: #00DCDC;
  }
}
@media screen and (min-width: 801px) {
  .mobile {
    display: none;
  }
  .pc {
    visibility: visible;
  }
}
</style>