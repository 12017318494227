<template>
  <div>
    <navbar v-if="!['login', 'register'].includes($route.name)"/>
    <router-view />
    <back-to-top bottom="5%" visibleoffset="500">
      <vs-button icon-pack="material-icons" icon="keyboard_arrow_up" class="shadow-lg btn-back-to-top back-to-top-button" />
    </back-to-top>
    <Footer v-if="!['login', 'register'].includes($route.name)"/>
  </div>
</template>


<script>
import Navbar from '../components/Navbar.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  },
  watch: {
  },
  created () {
    if (this.$route.params.lang === 'en') {
      this.$i18n.locale = 'en'
      this.$vs.rtl = false
    } else {
      this.$i18n.locale = 'ar'
      this.$vs.rtl = true
    }
  }
}
</script>
<style>
.back-to-top-button, .back-to-top-button.vs-button-primary.vs-button-filled {
  border-radius: 50%;
  background: #f59530 !important;
}
</style>